<template>
  <v-container style="padding-top:30px">
    <p style="line-height: 2" v-if="sampleData"><span style="font-size:18px;font-weight:800">SampleID: </span>{{sampleData.id}}</p>
    <v-divider style="margin-bottom:5px"></v-divider>
    <v-card style="border: 1px solid #cccccc" elevation="0">
      <div style="height:50px;background-color:#242a30;">
        <h2 style="color:white;line-height: 50px;padding-left: 20px;">基本信息</h2>
      </div>
      <div style="padding: 0 50px 50px 50px">
        <h2 style="margin:20px 0">样本基本信息</h2>
        <v-row>
          <v-col v-for="(value,key) in keyChart" v-if="['id','gender','age','nation'].includes(value)" :cols="3" style="display:flex;justify-content:center">
            <h3 style="font-size:18px;margin-right: 15px">{{key}}:</h3>{{ sampleData[value] }}
          </v-col>
        </v-row>
        <h2 style="margin:20px 0">体能分项成绩</h2>
        <v-row justify="center" align="center">
          <template v-for="(value,key,i) in keyChart" v-if="!['id','gender','age','nation','physicalFitnessScore','physicalFitnessRating'].includes(value)">
            <v-col :cols="[1,4,7,10,13,16,19,22,25,28,31,34,37].includes(i)?4:2" style="display:flex;justify-content:left">
              <h3 style="font-size:18px;margin-right: 15px">{{key}}:</h3>
            </v-col>
            <v-col :cols="1" style="display:flex;justify-content:left">
              {{ sampleData[value] }}  {{unit.hasOwnProperty(value) ? unit[value] : ''}}
            </v-col>
          </template>
        </v-row>
        <h2 style="margin:20px 0">体能总成绩</h2>
        <v-row>
          <v-col v-for="(value,key) in keyChart" v-if="['physicalFitnessScore','physicalFitnessRating'].includes(value)" :cols="4" style="display:flex;justify-content:center">
            <h3 style="font-size:18px;margin-right: 15px">{{key}}:</h3>{{ sampleData[value] }}
          </v-col>
        </v-row>
      </div>
      <div style="height:50px;background-color:#242a30;">
        <h2 style="color:white;line-height: 50px;padding-left: 20px;">体能雷达图</h2>
      </div>
      <div style="display:flex;justify-content:center;width:100%">
        <div style="height:700px;width:700px;display:flex;justify-content:center" id="radarChart"></div>
      </div>
      <div style="height:50px;background-color:#242a30;">
        <h2 style="color:white;line-height: 50px;padding-left: 20px;">TOP200基因表达量和甲基化区域信息</h2>
      </div>
      <div style="padding:30px">
        <el-table :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)" :border=true>
          <el-table-column
              v-for="(item,index) in tableHeader"
              :prop="item.value"
              :label="item.text"
              :fixed="false"
              :show-overflow-tooltip="true"
              min-width="130"
          >
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <div class="block" style="margin-top:15px;">
          <el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-sizes="[1,5,10,20]"
                         :page-size="pageSize"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="tableData.length">
          </el-pagination>
        </div>
      </div>
      <div style="height:50px;background-color:#242a30;">
        <h2 style="color:white;line-height: 50px;padding-left: 20px;">基因表达量和甲基化水平circos图</h2>
      </div>
      <div style="display:flex;justify-content:center;width:100%">
        <img :src="`/biobank/reportTemplate/5e7ac090233167dcfccd9031/group/dashboard/62c27cde46afc5003e62720e/circos/${sampleData.id}.png`" alt="" width="40%">
      </div>
    </v-card>

  </v-container>
</template>

<script>
  import * as echarts from "echarts";
  import {getWorkflow} from "../JS/utils";
  import axios from "axios";

  export default {
    name: "sampleChildrenPage",
    data: () => ({
      sampleData:{
        id:''
      },//结果页面要展示的样本结果数据
      keyChart:{ //样本体能信息表key的字典
        '样本ID': 'id',
        '性别': 'gender',
        '年龄': 'age',
        '民族': 'nation',
        '（有氧耐力）3000米跑成绩': '3000RunningResultTime',
        '3000米跑得分': '3000RunningResult',
        '3000米跑评级': '3000RunRating',
        '（绝对力量）握力成绩': 'gripStrength',
        '握力得分': 'gripScore',
        '握力评级': 'gripRating',
        '（上肢耐力）俯卧撑成绩': 'pushUpScoreNum',
        '俯卧撑得分': 'pushUpScore',
        '俯卧撑评级': 'pushUpRating',
        '（上肢爆发力）胸前跪抛球成绩': 'kneelingNum',
        '胸前跪抛球得分': 'kneelingScore',
        '胸前跪抛球评级': 'kneelingRating',
        '（下肢垂直爆发力）垂直纵跳成绩': 'verticalNum',
        '垂直纵跳得分': 'verticalScore',
        '垂直纵跳评级': 'verticalRating',
        '（下肢水平爆发力）立定跳远成绩': 'standingNum',
        '立定跳远得分': 'standingScore',
        '立定跳远评级': 'standingRating',
        '（速度）30米冲刺成绩': '30RunningNum',
        '30米冲刺得分': '30RunningScore',
        '30米冲刺评级': '30RunningRating',
        '（协调性）六边形跳成绩': 'hexagonNum',
        '六边形跳得分': 'hexagonScore',
        '六边形跳评级': 'hexagonRating',
        '（综合灵敏）T型跑成绩': 'TRunningNum',
        'T型跑得分': 'TRunningScore',
        'T型跑评级': 'TRunningRating',
        '（柔韧性）坐位体前屈成绩': 'sittingNum',
        '坐位体前屈得分': 'sittingScore',
        '坐位体前屈评级': 'sittingRating',
        '体能总分': 'physicalFitnessScore',
        '体能总评级': 'physicalFitnessRating'
      },
      unit:{
        "3000RunningResultTime": "秒",
        "gripStrength": "公斤",
        "pushUpScoreNum": "个",
        "kneelingNum": "米",
        "verticalNum": "厘米",
        "standingNum": "厘米",
        "30RunningNum": "秒",
        "hexagonNum": "秒",
        "TRunningNum": "秒",
        "sittingNum": "厘米"
      },
      tableHeader:[//top200数据表格表头
        {
          text:'基因名',
          value:'geneName'
        },{
          text:'基因表达量',
          value:'geneExpression'
        },{
          text:'甲基化水平',
          value:'methylationLevel'
        },{
          text:'甲基化分类',
          value:'methylationClassification'
        },{
          text:'基因和体能表型关系',
          value:'transcriptomeDifferences'
        },{
          text:'表达和甲基化',
          value:'differencesMethylationFitness'
        }
      ],
      tableData:[],//top200数据表格数据
      currentPage:1,
      pageSize:5,
      total: 0, // 总条数
      imageUrl: '',
      imgShow: false
    }),
    async mounted() {


    },
    async activated() {
      let that = this
      let val = this.$route.query.value
      this.sampleData = JSON.parse(val)
      this.getData()
      this.$nextTick(()=>{
        that.initDonutChart()
      })
    },
    methods: {
      /**
       * 雷达图画图函数
       */
      async initDonutChart(){
        let tenPhysical = ['3000RunningResult','gripScore','pushUpScore','kneelingScore','verticalScore','standingScore','30RunningScore','hexagonScore','TRunningScore','sittingScore'],
            sampleData = this.sampleData,
            indicator = [],
            value = []
        for (const key in sampleData) {
          if(tenPhysical.includes(key)){
            for (const keyChartItem in this.keyChart) {
              if(this.keyChart[keyChartItem] === key){
                indicator.push({
                  text:keyChartItem,
                  max:10
                })
                value.push(sampleData[key])
              }
            }
          }
        }
        //取十个体能维度的平均值数据
        if(!sessionStorage.getItem('averageScore')){
          let res = await getWorkflow({
            "filterOption": {
              "filters": {
                "workflow": '62f5b7b57f7126003ed01d71',
                "submission.id": {"$eq": "averageScore"}
              },
              "group": '61de80fa3f6359003ec98d55',
              "limit": 1
            }
          })
          sessionStorage.setItem('averageScore',JSON.stringify(res.results[0]['submission']['data']))
        }
        let averageScore = JSON.parse(sessionStorage.getItem('averageScore'))
        let averageScoreValue = []
        for (let i = 0; i < tenPhysical.length; i++) {
          averageScoreValue.push(averageScore[tenPhysical[i]])
        }
        var myChart = echarts.init(document.getElementById('radarChart'))
        myChart.setOption(
            {
              color:['blue','red'],
              title: {
                text: 'Physical difference'
              },
              legend: {
                data: ['平均值', '体能评分']
              },
              radar: {
                // shape: 'circle',
                indicator: indicator,
                splitNumber: 10,
              },
              series: [
                {
                  name: 'Budget vs spending',
                  type: 'radar',
                  data: [{
                    value:value,
                    name:'体能评分',
                    label: {
                      show: true,
                      formatter: function (params) {
                        return params.value;
                      }
                    }
                  },
                    {
                    value:averageScoreValue,
                    name:'平均值',
                    lineStyle: {
                      color: 'red'
                    },
                    label: {
                      show: true,
                      formatter: function (params) {
                        return params.value;
                      }
                    }
                  }]
                }
              ]
            }
        );
      },
      /**
       * 获取top200基因数据
       * @returns {Promise<void>}
       */
      async getData(){
        //请求接口获取top200数据
        let res = await getWorkflow({
          "filterOption": {
            "filters": {
              "workflow": '62f5ff087f7126003ed01d7d',
              'submission.sampleId': this.sampleData.id
            },
            "group": '61de80fa3f6359003ec98d55',
            "limit": 500
          }
        })
        let results = res.results
        this.tableData = []
        for (let i = 0; i < results.length; i++) {
          //对甲基化水平值取两位小数
          if(Number(results[i]['submission']['methylationLevel'])){
            results[i]['submission']['methylationLevel'] = Number(results[i]['submission']['methylationLevel']).toFixed(2)
          }
          if(results[i].submission.methylationClassification === 'NDMG'){
            results[i].submission.methylationClassification = '差异甲基化'
          }else{
            results[i].submission.methylationClassification = ''
          }
          this.tableData.push(results[i].submission)
        }
      },
      //每页条数改变时触发 选择一页显示多少行
      handleSizeChange(val) {
        this.currentPage = 1;
        this.pageSize = val;
      },
      //当前页改变时触发 跳转其他页
      handleCurrentChange(val) {
        this.currentPage = val;
      },
    }
  }
</script>

<style scoped>

</style>
